<template>
  <v-data-table
    v-model="arInvoiceTypeList"
    :headers="headers"
    :items="items"
    :loading="loading"
    class="dt-listing-simple"
    disable-pagination
    disable-sort
    hide-default-footer
    show-select
    @item-selected="onToggle"
  >
    <template #[`header.data-table-select`]>
      <span />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, VModel, Watch } from "vue-property-decorator";
import {
  Company,
  InvoiceTypeCollection,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import CacheMixin from "@/mixins/CacheMixin";
import { find, findIndex, map, set } from "lodash";
import { AppModule } from "@/store/app";
import { ConfigModule } from "@/store/config";
import useInvoice from "@/composables/invoice";
import { EventBus } from "@/services/event-bus";

type DataTableSelectRow = {
  item: InvoiceTypeData;
  value: boolean;
};

@Component
export default class InvoiceTypes extends Mixins(DataTableMixin, CacheMixin) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
  loading = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
  ];
  arInvoiceTypeList: InvoiceTypeData[] = [];
  arUsedIvoiceTypeIdList: number[] = [];
  obCollection: InvoiceTypeCollection = new InvoiceTypeCollection();

  get cacheKey() {
    return "InvoiceType";
  }

  get cacheIdentifier() {
    return "models";
  }

  get company() {
    return AppModule.company;
  }

  get companyId(): number | undefined {
    return this.obCompany ? this.obCompany.id : undefined;
  }

  get items(): InvoiceTypeData[] {
    let arItems = this.cache<InvoiceTypeData[]>();

    if (!arItems || !arItems.length) {
      arItems = this.obCollection.getModelList() as InvoiceTypeData[];
    }

    return map(arItems, (obItem) => {
      let bSelectable = true;

      if (
        this.arUsedIvoiceTypeIdList.includes(obItem.id) &&
        !!find(this.arInvoiceTypeList, { id: obItem.id })
      ) {
        bSelectable = false;
      }
      set(obItem, "isSelectable", bSelectable);

      return obItem;
    });
  }

  @Watch("companyId")
  async onChangeCompany() {
    this.loading = true;
    await this.loadCompanyInvoiceTypes();
    this.loading = false;
  }

  async load() {
    this.loading = true;
    await this.loadCompanyInvoiceTypes();
    // const arItems = this.cache<InvoiceTypeData[]>();

    this.arUsedIvoiceTypeIdList = await useInvoice().listByType<number[]>(
      "invoice_type_id",
      this.obCompany.id
    );

    // if (!force && arItems && arItems.length) {
    //   this.loading = false;
    //   return;
    // }

    this.obCollection.clear();
    await this.obCollection.list();
    // this.cache(this.obCollection.getModelList());

    this.loading = false;
  }

  async loadCompanyInvoiceTypes() {
    if (!this.obCompany || !this.obCompany.id) {
      return;
    }

    const obResponse = await this.obCompany.getInvoiceTypes();
    this.arInvoiceTypeList = obResponse.getData().data;
  }

  async onToggle(obValue: DataTableSelectRow) {
    const bSelected = obValue.value;
    const obItem = obValue.item;
    const arInvoiceTypeIdList = map(this.arInvoiceTypeList, "id");

    // Add selected id
    if (bSelected) {
      arInvoiceTypeIdList.push(obItem.id);
    } else {
      // Remove selected id
      const idx = findIndex(
        arInvoiceTypeIdList,
        (itemId) => itemId === obItem.id
      );
      if (idx !== -1) {
        arInvoiceTypeIdList.splice(idx, 1);
      }
    }

    this.loading = true;

    // Store selected invoice types ids
    await this.obCompany.addUpdateInvoiceTypes(arInvoiceTypeIdList);

    // Check for current selected company to reload invoice types
    if (this.obCompany.id === this.company.id) {
      await ConfigModule.loadCompanyInvoiceTypes(true);
    }

    this.loading = false;
  }

  created() {
    EventBus.on("company.update.modules", async () => {
      await this.load();
    });
  }

  beforeDestroy() {
    EventBus.off("company.update.modules");
  }

  mounted() {
    this.mapDTHeaders();
    this.load();
  }
}
</script>
