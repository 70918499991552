<template>
  <v-autocomplete
    v-model="companyId"
    :items="items"
    :label="$t('select.company')"
    :loading="loading"
    :search-input.sync="sSearch"
    dense
    item-text="firm.name"
    item-value="id"
    outlined
    v-bind="$attrs"
    @change="onSelect"
  >
    <template #item="{ item }">
      <company-preview :company="item" hide-title />
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, VModel, Vue, Watch } from "vue-property-decorator";
import { Company, CompanyCollection } from "@planetadeleste/vue-mc-gw";
import { DebounceFunction } from "@/plugins/helpers";
import { debounce } from "lodash";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";

@Component({
  components: { CompanyPreview },
})
export default class CompanySelect extends Vue {
  @VModel({ type: [String, Number] }) companyId!: number;

  obCompanyCollection = new CompanyCollection();
  sSearch = "";
  loading = false;
  fnDebounceSearch!: DebounceFunction;

  get items() {
    return this.obCompanyCollection.getModelList();
  }

  get selected(): Company | undefined {
    return this.companyId
      ? this.obCompanyCollection.find({ id: this.companyId })
      : undefined;
  }

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  async mounted() {
    await this.search();
  }

  onSelect(sValue: number) {
    this.$emit("input", sValue);
    this.$emit("change", this.obCompanyCollection.find({ id: sValue }));
  }

  async search() {
    const obCollection = new CompanyCollection();
    obCollection.page(1);

    if (this.loading) {
      return;
    }

    if (this.sSearch && this.sSearch.length) {
      // Prevent search on selected item
      if (this.selected && this.selected.get("firm.name") === this.sSearch) {
        return;
      }
      obCollection.filterBy({ search: this.sSearch });
    } else {
      obCollection.filterBy({ take: 5 });
    }

    this.loading = true;
    await obCollection.fetch();
    this.obCompanyCollection.clear();
    this.obCompanyCollection.add(obCollection.models);
    this.loading = false;

    if (this.selected) {
      this.sSearch = this.selected.get("firm.name");
    }
  }
}
</script>
